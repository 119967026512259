@import "../node_modules/bulma/sass/utilities/initial-variables";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&family=VT323&display=swap');


$family-serif: 'VT323', sans-serif;
$family-primary: $family-serif;

$family-primary: 'Poppins', sans-serif;

$primary: #6bc5e8;

$link: $primary;
$info:$primary;
$text: #000000;


@import "../node_modules/bulma/bulma";




td ,th{
    font-size: min(1.3vw, 15px);
}